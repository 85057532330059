import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  // Live Server - https://edinemenu.com/webapi/
  // Test Server - https://test.edinemenu.com/webapi/
  // Local Server - http://localhost:51638/

  getApiURL() {
    return 'https://edinemenu.com/webapi/'; //live
    //return 'https://beta.edinemenu.com/webapi/'; //test
    // return 'https://test.edinemenu.com/webapi/';
    // return 'https://test.edinemenu.com/webapi/';
    //return 'http://localhost:51638/';
    //return 'https://edineapi.azurewebsites.net/';
  }


  getMainUrl() {
    //return 'https://edinemenu.com/webapi/';
    // return 'https://test.edinemenu.com/';
    //return 'http://localhost:51638/';
    //return 'https://edineapi.azurewebsites.net/';
  }
}
