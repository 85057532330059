import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.service';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  BaseURL: string;
  defaultSidebar : string = 'full'
  TokenUrl: string;
  constructor(private http: HttpClient , private meta : Meta , private global : GlobalService) {
    this.BaseURL = this.global.getApiURL() + 'api/';
    this.TokenUrl = this.global.getApiURL() + 'api/';
  }

  // updateDescription(desc: string) {
  //   this.meta.updateTag({ name: 'description', content: desc })
  // }
  methodGet(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + sessionStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.BaseURL + url, httpOptions);
  }
  methodGetTest(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + sessionStorage.getItem("userToken")
      }),
    };
    return this.http.get( url, httpOptions);
  }
  methodOptions(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + sessionStorage.getItem("userToken")
      }),
    };
    return this.http.options(this.BaseURL + url, httpOptions);
  }
  methodDelete(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionStorage.getItem("userToken"),
      }),
    };
    return this.http.delete(this.BaseURL + url, httpOptions);
  }
  methodPost(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPostFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + sessionStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPut(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPutFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + sessionStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }

  // handleError(error) {
  //   console.log(error);
  //   let errorMessage =
  //     "Failed !  url :" + error.url + "  Message : " + error.error.Message;
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }
}
